<template>
  <v-row>
    <v-col
      v-for="(field, index) of fields"
      :cols="field.colSize || 12"
      :key="index"
    >
      <input-v
        v-model="value[field.key]"
        :label="field"
        :opts="opts"
        :editable="!sending && !field.disabled"
        @blur="field.blur  ? field.blur : () => {}"
        @input="field.input"
        @update:valid="validMap.set(field.key, $event)"
      ></input-v>
    </v-col>

    <permissions-field
      v-model="value.permissoes"
      :id="value.empresaId"
      :opts="opts"
      :sending.sync="sending"
      :userType="userType"
      @update:valid="validMap.set('permissions', $event)"
    ></permissions-field>

    <action-buttons
      :deleteUser="!isNewUser"
      :resetPassword="!isNewUser"
      :sending="sending"
      :userEmail="user.email"
      :userId="user.id"
      :userName="user.nome"
      @error="$emit('error', $event)"
      @save="doSave()"
      @update:sending="$emit('sending', $event)"
    ></action-buttons>
  </v-row>
</template>

<script>
import { sortBy } from 'lodash';
import { UserTypeEnum } from '@/core/enums/user-types';

export default {
  components: {
    "action-buttons": () => import("./action.buttons.vue"),
    "input-v": () => import("@/components/input-v.vue"),
    "permissions-field": () => import("./permissionsField"),
  },
  computed: {
    fields: function () {
      const allFields = [
        {
          key: "email",
          name: "Email",
          colSize: 6,
          input: this.autofillUser,
          type: this.$fieldTypes.TEXT,
          placeholder: 'nome@fornecedor.com.br',
          rules: [{ rule: "required" }, { rule: "email" }],
        },
        {
          key: "usuario",
          name: "Usuário",
          colSize: 6,
          type: this.$fieldTypes.TEXT,
          placeholder: 'Será igual ao e-mail por padrão',
          rules: [{ rule: "required" }],
          blur: () => {
            if (!this.value.usuario) {
              this.fillUser = true;
              this.value.usuario = this.value.email;
            }
          },
          input: () => this.fillUser = false,
        },
        {
          key: "empresaId",
          name: "Empresa",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "clientes", key: "id", name: "fantasia" },
          rules: [{ rule: "required" }],
          colSize: this.isNewUser ? 6 : 5,
          input: this.doLoadColaboradores,
        },
        {
          key: "funcionarioId",
          name: "Colaborador",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { toEdit: this.colaboradores, key: "id", name: "label" },
          rules: [{ rule: "required" }],
          colSize: this.isNewUser ? 6 : 5,
        },
        {
          hide: !this.isNewUser,
          key: "senha",
          name: "Senha Provisória",
          colSize: 10,
          type: this.$fieldTypes.TEXT,
          placeholder: 'Novas contas precisam de uma senha inicial',
          rules: [{ rule: "required" }],
        },
        {
          key: "ativo",
          name: "Status",
          colSize: 2,
          type: this.$fieldTypes.SWITCH,
          rel: {
            toEdit: [
              { id: true, nome: "Ativo" },
              { id: false, nome: "Inativo" },
            ],
            key: "id",
            name: "nome",
          },
        },
      ];

      return allFields.filter(({ hide }) => !hide);
    },
    userType: function () {
      return UserTypeEnum.COLABORADOR;
    },
  },
  created: async function () {
    try {
      this.$emit('update:sending', true);
      this.updateValue(this.user);

      if (!this.isNewUser && this.value.empresaId) {
        await this.doLoadColaboradores(this.value.empresaId);
      }
    } catch (error) {
      this.$emit('error', error);
    } finally {
      this.$emit('update:sending', false);
    }
  },
  data: function () {
    const validMap = new Map();
    return {
      colaboradores: [],
      fillUser: true,
      permissoesList: {},
      validMap,
      sending: false,
      value: {
        ativo: true,
        email: null,
        empresaId: null,
        funcionarioId: null,
        permissoes: [],
        senha: null,
        usuario: null,
      },
    };
  },
  methods: {
    autofillUser: function (nextValue) {
      if (this.fillUser) {
        this.value.usuario = nextValue;
      }
    },
    doLoadColaboradores: async function (empresaId) {
      try {
        if (!isFinite(empresaId)) {
          return this.colaboradores = [];
        }

        this.$emit('update:sending', true);
        const resource = this.apiResource(`/v1/rh/${empresaId}/selecao`);
        const response = await resource.get();

        if (response.error) {
          throw response;
        }

        this.colaboradores = sortBy(response.colaboradores, "nome").map(({ id, matricula, nome }) => ({
          id,
          label: matricula ? `${matricula} — ${nome}` : nome,
          matricula,
          nome,
        }));
      } catch (error) {
        this.colaboradores = [];
        this.$emit('error', error);
      } finally {
        this.$emit('update:sending', false);
      }
    },
    doSave: function () {
      const fieldErros = [];
      this.validMap.forEach((validation, key) => {
        if (!validation()) {
          fieldErros.push(key);
        }
      });

      if (fieldErros.length > 0) {
        const fieldNames = fieldErros
          .map((fieldKey) => {
            const found = this.fields.find(({ key }) => key === fieldKey);

            if (found) {
              return found.name;
            }

            return fieldKey === 'permissions' ? 'Permissões' : fieldKey;
          })
          .join(', ');
        return this.$emit('error', { error: `Campos inválidos: ${fieldNames}.` });
      }

      const { id } = this.user;
      const { permissoes, ...values } = this.value;
      const nome = this.colaboradores.find(({ id }) => id === this.value.funcionarioId)?.nome || null;
      const body = {
        ...values,
        tipo_usuario: UserTypeEnum.COLABORADOR,
        nome,
        permissoes: [{
          empresa_id: this.value.empresaId,
          permissoes,
        }],
      };

      if (id && isFinite(id)) {
        body.id = id;
      }

      if (!body.senha) {
        delete body.senha;
      }

      this.$emit('save', body);
    },
    parseUserPermissoes: function ({ empresa_id, permissoes }) {
      const perms = Object.entries(permissoes || {}).reduce(
        (accA, [module, resources]) => {
          const keys = Object.entries(resources || {}).reduce(
            (accB, [resource, actions]) => {
              const keys = Object.keys(actions || {}).map(
                (action) => `${module}.${resource}.${action}`
              );
              return [...accB, ...keys];
            },
            []
          );
          return [...accA, ...keys];
        },
        []
      );
      return { empresa_id, permissoes: perms };
    },
    updateValue: function (nextValue = {}) {
      const funcionarioId = nextValue.funcionarioId || this.colaboradores.find(({ nome }) => nome === nextValue.nome)?.id || null;
      const permissoes = Array.isArray(nextValue.empresas) && nextValue.empresas.length > 0
        ? this.parseUserPermissoes(nextValue.empresas[0])
        : { empresa_id: null, permissoes: [] };

      this.value = {
        ativo: nextValue.ativo,
        email: nextValue.email,
        empresaId: permissoes.empresa_id,
        funcionarioId,
        permissoes: permissoes.permissoes,
        senha: nextValue.senha,
        usuario: nextValue.usuario,
      };
    }
  },
  props: {
    isNewUser: { type: Boolean, default: false },
    opts: { type: Object, default: () => ({}) },
    user: { type: Object, default: () => ({}) },
  },
  watch: {
    user: {
      deep: true,
      handler: function (nextValue) {
        this.updateValue(nextValue);
      },
    },
  },
}
</script>
